/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <ContratObjectifEditorDialog
      v-if="activeContratObjectif != null"
      ref="contratObjectifEditorDialog"
      :item="activeContratObjectif"
      :title="'Ajout de contrat d\'objectif'"
      @save="saveContratObjectif"
    />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <!-- <form v-if="false" class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field col-12 col-md-3">
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form> -->
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des contrats objectifs</h4>
        </div>
        <div class="btn-group">
          <a
            @click.prevent="showContratObjectifEditorDialog"
            href=""
            class="ms-2 style_btn btn btn-warning"
          >
            Créer un contrat d'objectif
          </a>
        </div>
        <PMessage
          v-show="errorMessage"
          :severity="'error'"
          @close="errorMessage = null"
          >{{ errorMessage }}</PMessage
        >
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: cand }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusOffreColor(cand.status)"
                    v-if="cand.status"
                    >{{ cand.status }}
                  </PTag>
                </template>
                <template #cell(isEvalue)="{ item: de }">
                  <span class="text-uppercase">
                    <i
                      v-if="de.isEvalue"
                      v-tooltip.left="'Evaluée'"
                      class="bi bi-check-circle text-success"
                      style="font-size: 1.5rem"
                      v-badge.success="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                    <i
                      v-else
                      v-tooltip.left="'Non évaluée'"
                      class="bi bi-slash-circle text-danger"
                      style="font-size: 1.5rem"
                      v-badge.danger="de.typeCandidature == 'RECOMMANDE' ? 'R' : 'S'"
                    ></i>
                  </span>
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(dateDu)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateDu).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(dateAu)="{ item: de }">
                  <span class="text-uppercase">{{
                    $dayjs(de.dateAu).format("DD-MM-YYYY")
                  }}</span>
                </template>
                <template #cell(lastDateMoisContratObjectif)="{ item: de }">
                  <span class="text-uppercase">{{
                    de.lastDateMoisContratObjectif != null
                      ? $dayjs(de.lastDateMoisContratObjectif).format("MMM YYYY")
                      : "-"
                  }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>
                <template #cell(posteOccupe)="{ item: de }">
                  <div class="ellipsis" v-tooltip.top="de.posteOccupe">
                    {{ de.posteOccupe | truncate() }}
                  </div>
                </template>

                <template #row-details="row">
                  <PTabView :activeIndex.sync="activeTab" class="mt-5">
                    <PTabPanel header="Objectifs par Antenne">
                      <ObjectifParAntenneList
                        v-if="activeTab == 0"
                        :contratObjectif="row.item"
                        :extraQueryArg="`contratObjectifId=${row.item.id}`"
                      />
                    </PTabPanel>
                    <PTabPanel header="Objectifs par agent">
                      <!-- <ObjectifAgentList
                        :extraQueryArg="`contratObjectifId=${row.item.id}`"
                      /> -->
                      <ObjectifParAgentList
                        :contratObjectif="row.item"
                        v-if="activeTab == 1"
                        :extraQueryArg="`contratObjectifId=${row.item.id}`"
                      />
                    </PTabPanel>
                    <!-- <PTabPanel header="Objectifs par indicateur">
                      <ObjectifParAgentList
                        :contratObjectif="row.item"
                        :extraQueryArg="`contratObjectifId=${row.item.id}`"
                      />
                    </PTabPanel> -->
                  </PTabView>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Voir les missions'"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                    <!-- <a class="btn btn-warning rounded-0 btn-sm me-1" v-tooltip.top="'Présence au poste'"  @click.stop="(pp)" ><i class="bi bi-person-check"></i></a> -->
                    <!-- <a class="btn btn-primary rounded-0 btn-sm me-1" v-tooltip.top="'Prise de service'"  @click.stop="showPriseDeServiceEditorDialog(pp)" ><i class="bi bi-chevron-bar-expand"></i></a> -->
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1" v-tooltip.top="'Cessation de service'"  @click.stop="showCessationServiceEditorDialog(pp)" ><i class="bi bi-journal-x"></i></a> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";
import CAutoComplete from "../../../../components/common/CAutoComplete.vue";

import ContratObjectifEditorDialog from "../../../../components/espace/common/suivi-evaluation/editor/ContratObjectifEditorDialog.vue";
import ObjectifAgentList from "./ObjectifAgentList.vue";
import ObjectifParAntenneList from "./ObjectifParAntenneList.vue";
import ObjectifParAgentList from "./ObjectifParAgentList.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    offreId: {
      type: Number,
      default: null,
    },
  },
  components: {
    ContratObjectifEditorDialog,
    ObjectifAgentList,
    ObjectifParAntenneList,
    ObjectifParAgentList,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activeTab: 0,
      activePriseDeService: {},
      activeCessationService: {},
      activeContratObjectif: {},
      contratObjectif: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      nonEvalueesCountLoading: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      placementValidation: {},
      currentOffre: {},
      activeTransmission: {},
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      activeRecommandation: {
        demandeur: [],
      },
      parAeEnregistres: [],
      exportActions: [
        // {
        // 	label: 'PDF',
        // 	icon: 'pi pi-file-pdf',
        // 	command: () => {
        //     const data = XLSX.utils.json_to_sheet(this.tableData.map(el => ({ ...el, ...el.azoli })))
        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
        // 	}
        // },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
      ],
      filterOptions: [],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeCandidature: {},
      activeCandidature: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeCandidature = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    emptyPlacementVal() {
      return {
        posteOccupe: null,
        observation: null,
        typePlacement: null,
        natureContrat: null,
        dateDebut: null,
        dateFin: null,
      };
    },
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "ref", label: "Ref" },
        { key: "libelle", label: "Contrat d'objectif" },
        { key: "dateDu", label: "Début" },
        { key: "dateAu", label: "Fin" },
        { key: "status", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    // currentOffre() {
    //   const offre = this.mxFilteredItems[0] != null? this.mxFilteredItems[0].offre : {}
    //   return offre
    // }
  },
  methods: {
    ...mapActions({
      createOrUpdateContratObjectif: "contratObjectif/createOrUpdateContratObjectif",
      createOrUpdateArretContrat: "arretContrat/createOrUpdateArretContrat",
      recommanderProfils: "offre/recommanderProfils",
    }),
    showContratObjectifEditorDialog() {
      this.activeContratObjectif = {};
      this.$refs.contratObjectifEditorDialog.show();
    },
    saveContratObjectif(contratObjectif) {
      this.createOrUpdateContratObjectif(contratObjectif)
        .then((data) => {
          this.$toast.success("Présence au poste sauvegardée avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    getStatusOffreColor(status) {
      let color = "";
      if (status == "CANDIDATE") {
        color = "primary";
      } else if (status == "RETENU") {
        color = "success";
      } else if (status == "PRESELECTIONNE") {
        color = "warning";
      } else if (status == "RECALE") {
        color = "danger";
      } else if (status == "PROPOSE") {
        color = "info";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "contratObjectif",
        search: this.search,
        fetcherMethod: "contratObjectif/fetchContratObjectifs",
        dataGetter: "contratObjectif/contratObjectifs",
        paginationGetter: "contratObjectif/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `createdAfter=${dateDeb}&createdBefore=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeCandidature = { ...demandeur };
      this.$router.push({ name: "espace.conseiller.de.edit" });
    },
    getCurrentOffre() {
      const offre = this.mxFilteredItems[0] != null ? this.mxFilteredItems[0].offre : {};
      return offre;
    },
    accepterCandidature(candidature) {
      this.placementValidation = {
        ...this.emptyPlacementVal,
        candidature,
      };
      this.$refs.contratObjectifEditorDialog.show();
    },
    exigerCandidature(candidature) {
      this.placementValidation = {
        ...this.emptyPlacementVal,
        candidature,
      };
      this.$refs.contratObjectifEditorDialog.show();
    },
    async retirerRecommanationCandidature(candidature) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment retirer cette candidature recommandée de ${candidature.candidat.nomPrenom} ?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.retirerRecommanation({
            ...candidature,
          }).then((data) => {
            this.$toast.success("Recommandation retirée avec succès!", {
              position: "top-right",
              duration: 8000,
            });
            // this.queryStatistics()
          });
        },
      });
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
